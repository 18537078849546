@import "./partials/variables";
@import "./partials/navbar";
@import "./partials/navbar.scss";
@import "./partials/sidebar";
@import "./partials/themes";

@import "./partials/dashboard";


.icon-style {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid;
  font-size: .9rem;
}

body {
  height: 100vh;
  margin: 0;
  padding-top: 60px;
}

.wrapper {
  height: 100%;

  main {
    display: flex;
    height: 100%;

    .pages {
      overflow: auto;
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;  
      opacity: .7;
      display: none;

      &.show {

        display: block;

        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  &.toggeled-sidebar {
    main {
      .overlay {
        display: block;

        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.toast {
  opacity: 1;
}

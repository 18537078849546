/* You can add global styles to this file, and also import other style files */
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

$hamburger-padding-x : 0px !default;
$hamburger-padding-y : 0px !default;
$hamburger-layer-width : 25px !default;
$hamburger-layer-height : 2px !default;
$hamburger-layer-spacing : 5px !default;
$hamburger-layer-color : #9b9ea2 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity : 0.7 !default;

// @import '~bootstrap/scss/bootstrap';
// @import '~font-awesome/scss/font-awesome';
// @import '~hamburgers/_sass/hamburgers/hamburgers';
// @import '~ngx-toastr/toastr-bs4-alert';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import "~ng-pick-datetime/assets/style/picker.min.css";

@import './assets/lightning-admin';

.required:after {
  content: "*";
  color: red;
}

body {
  padding-top: 60px !important;
}

form .form-group {
  margin-bottom: .8rem;
}

#spinner {
  position: fixed;
  top: 0%;
  left: 0;
  bottom: 0%;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: whitesmoke;
  opacity: 75%;
}

#spinnertext {
  position: fixed;
  top: 45%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  color: rgb(18, 82, 18);
  text-align: center;
  font-size: x-large;
  //font-weight: 200;
}

.forgotPassTopMargin {
  margin-top: 15%;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(116, 114, 114, 0.8), rgba(151, 148, 148, 0.8));
  background: -webkit-radial-gradient(rgba(168, 166, 166, 0.8), rgba(172, 167, 167, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

// css to make invalid text fixed start
.invalid-feedback,
.invalid-tooltip {
  position: relative;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  margin-bottom: 0rem;
}

// css to make invalid text fix end 

// customer app theme setting preview css start from here 
:root {
  --header-color: #034131;
  --footer-color: #241C15;
  --cat-button-color: #deb887;
  --body-bg-color: #d8eacc;
  --secondary-bg-color: #ffffff;
  --ok-button-color: #00046B;
  --cancel-button-color: #dc3545;
  --menu-button-color: #25F6AF;
  --left-menu-color: #ffffff;
}

$header: var(--header-color);
$footer: var(--footer-color);
$catButton: var(--cat-button-color);
$bodyBg: var(--body-bg-color);
$secondaryBg: var(--secondary-bg-color);
$okButton: var(--ok-button-color);
$cancelButton: var(--cancel-button-color);
$menuButton: var(--menu-button-color);
$leftMenu: var(--left-menu-color);

.bodyPreview {
  background-color: $bodyBg;
}

.secondaryBgPreview {
  background-color: $secondaryBg;
}

.headerPreview {
  background-color: $header;
}

.footerPreview {
  background-color: $footer;
}

.okBtnPreview {
  background-color: $okButton;
  border-color: $okButton;
}

.okBtnPreview:hover {
  background-color: $okButton;
  border-color: $okButton;
}

.cancelBtnPreview {
  background-color: $cancelButton;
  border-color: $cancelButton;
}

.cancelBtnPreview:hover {
  background-color: $cancelButton;
  border-color: $cancelButton;
}

.menuBtnPreview {
  color: #000;
  background-color: $menuButton;
}

.leftMenuPreview {
  background-color: $leftMenu;
}

.scroll-category {
  position: sticky;
  //top: 75px;
  background: $bodyBg;
  z-index: 1;
}

.catBtnPreview {
  border-color: $catButton;
  color: #212529;
  text-align: center;
  border-radius: 10px;
  line-height: 8px;
  height: 42px;
  margin: 10px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  display: flex;
  background-color: $catButton;

  p {
    align-self: center;
    margin: 0;
  }
}

/*----  Main Style  ----*/
#cards_landscape_wrap-2 {
  // text-align: center;
  // background: #f7f7f76e;
  // border-radius: 5px;
  // border: 1px solid #cccccc87;
}

#cards_landscape_wrap-2 .container {
  padding-top: 10px;
  padding-bottom: 100px;
}

#cards_landscape_wrap-2 a {
  text-decoration: none;
  outline: none;
}

#cards_landscape_wrap-2 .card-flyer {
  border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .image-box {
  background: #ffffff;
  overflow: hidden;
  // box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.50);
  // border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .image-box img {
  -webkit-transition: all .9s ease;
  -moz-transition: all .9s ease;
  -o-transition: all .9s ease;
  -ms-transition: all .9s ease;
  width: 180px;
  height: 100%;
}

#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}

.image-box {
  width: 170px;
  height: 148px;
}

#cards_landscape_wrap-2 .card-flyer .text-box {
  text-align: center;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container {
  // padding: 30px 18px;
  width: 65%;
}

#cards_landscape_wrap-2 .card-flyer {
  background: $secondaryBg !important;
  margin-top: 0px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  // box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
  height: 150px;
}

#cards_landscape_wrap-2 .card-flyer:hover {
  background: $secondaryBg;
  transition: all 0.2s ease-in;
  margin-top: 5px;
}

#cards_landscape_wrap-2 .card-flyer .text-box p {
  // margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #000000;
}

#cards_landscape_wrap-2 .card-flyer .text-box h6 {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  font-family: 'Roboto Black', sans-serif;
  letter-spacing: 1px;
  color: #241C15;
}

.card-flyer:hover {
  // height: 400px;
  // padding: 10px
  border: 2px solid cadetblue;
}

.text-box {
  display: flex;
  // justify-content: space-around;
}

.image-box {
  position: relative;
  width: auto;
}

.add_btn_theme {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  background-color: #ffc107;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  box-shadow: -5px 5px 10px rgb(0 0 0 / 40%), 0 0 4px #fff;
}

i.bi.bi-plus {
  font-size: 20px;
  font-weight: bold;
}

.add_btn:hover {
  background-color: #fff;
  color: #000;
}

.item-name-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pro_des {
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  // width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-container h6 {
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}

.pro_price {
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px !important;
  font-weight: 600 !important;
}

// customer app theme setting preview css ends